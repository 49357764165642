import { mapHelper } from "@/utils/common.js";

//页面类型
const pagesType = [
  {
    value: 3,
    label: "第三方h5应用",
  },
  {
    value: 4,
    label: "第三方小程序",
  },
];

const { map: pagesTypeMap, setOps: pagesTypeOps } = mapHelper.setMap(pagesType);

//入口类型
const inType = [
  {
    value: 1,
    label: "小程序",
  },
];
const { map: inTypeMap, setOps: inTypeOps } = mapHelper.setMap(inType);
//上架状态
const shelfSts = [
  {
    value: 0,
    label: "待上架",
  },
  {
    value: 1,
    label: "审核中",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: shelfStsMap, setOps: shelfStsOps } = mapHelper.setMap(shelfSts);
//应用类型
const appType = [
  {
    value: undefined,
    label: "全部应用",
  },
  {
    value: "0",
    label: "标准应用",
  },
  {
    value: "1",
    label: "第三方开发应用",
  },
];
const { map: appTypeMap, setOps: appTypeOps } = mapHelper.setMap(appType);
//应用状态
const appStatusType = [
  {
    value: "",
    label: "正常",
  },
  {
    value: 1,
    label: "正常",
  },
  {
    value: 2,
    label: "已停用", //已下架
  },
  {
    value: 3,
    label: "已停用", //已删除
  },
  {
    value: 4,
    label: "已停用", //审核中
  },
];
const { map: appStatusTypeMap, setOps: appStatusTypeOps } =
  mapHelper.setMap(appStatusType);
//上架状态
const shelfStatus = [
  {
    value: undefined,
    label: "全部",
  },
  {
    value: 1,
    label: "已上架",//上架
  },
  {
    value: 2,
    label: "待上架", //下架
  },

];
const { map: shelfStatusMap, setOps: shelfStatusOps } =
  mapHelper.setMap(shelfStatus);
export {
  pagesType,
  pagesTypeMap,
  inType,
  inTypeMap,
  appType,
  appTypeMap,
  shelfStsMap,
  shelfSts,
  appStatusTypeMap,
  shelfStatusOps,
  shelfStatusMap
};
