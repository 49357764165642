var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addApplication"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"应用名称","rules":[
        { required: true, message: '请输入应用名称', trigger: 'blur' },
      ],"prop":"modelTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入应用名称","maxlength":8,"width":250},model:{value:(_vm.form.modelTitle),callback:function ($$v) {_vm.$set(_vm.form, "modelTitle", $$v)},expression:"form.modelTitle"}})],1),_c('el-form-item',{attrs:{"label":"应用图标","rules":[
        {
          required: true,
          message: '请上传应用图标',
          trigger: ['change', 'blur'],
        },
      ],"prop":"modelIconPic"}},[_c('v-upload',{attrs:{"limit":1,"myHeaders":_vm.myHeaders,"imgUrls":_vm.form.modelIconPic,"tips":"建议上传66px*66px"},on:{"update:myHeaders":function($event){_vm.myHeaders=$event},"update:my-headers":function($event){_vm.myHeaders=$event},"update:imgUrls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"应用简介","rules":[
        { required: true, message: '请输入应用简介', trigger: 'blur' },
      ],"prop":"modelDesc"}},[_c('v-input',{attrs:{"type":"textarea","placeholder":"请输入应用简介","width":350},model:{value:(_vm.form.modelDesc),callback:function ($$v) {_vm.$set(_vm.form, "modelDesc", $$v)},expression:"form.modelDesc"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }