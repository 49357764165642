<template>
  <div class="addApplication">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="应用名称"
        :rules="[
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ]"
        prop="modelTitle"
      >
        <v-input
          placeholder="请输入应用名称"
          :maxlength="8"
          v-model="form.modelTitle"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="应用图标"
        :rules="[
          {
            required: true,
            message: '请上传应用图标',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="modelIconPic"
      >
        <v-upload
          :limit="1"
          :myHeaders.sync="myHeaders"
          :imgUrls.sync="form.modelIconPic"
          tips="建议上传66px*66px"
        />
      </el-form-item>

      <el-form-item
        label="应用简介"
        :rules="[
          { required: true, message: '请输入应用简介', trigger: 'blur' },
        ]"
        prop="modelDesc"
      >
        <v-input
          type="textarea"
          placeholder="请输入应用简介"
          v-model="form.modelDesc"
          :width="350"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  communityInfo,
  updateCommunity,
  getBuriedPointUrl,
  kindsListUrl,
  tenantListUrl,
} from "./api";
import { _localStorage } from "@/utils/utils";
import { downloadHelper } from "@/utils/func";
import encrypt from "@/utils/encrypt";
import { pagesType, inType } from "./map";
export default {
  name: "addApplication",
  data() {
    return {
      myHeaders: {},
      submitConfig: {
        queryUrl: communityInfo,
        submitUrl: updateCommunity,
      },
      form: {
        id: "",
        modelTitle: "",
        modelIconPic: "",
        modelDesc: "",
      },
      id: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.setHearer();
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ appId: id });
      this.id = id;
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },

  methods: {
    setHearer() {
      let key = "NY83ekL2xPuucunRMVWe5mThijh36i"; // 一串固定密文
      let value = _localStorage.getItem("account");
      let password = encrypt.sha1(encrypt.md5.hexMD5(value + key));
      this.myHeaders = {
        account: value,
        password,
      };
    },
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      this.form.id = this.id;
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addApplication {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 170px !important;
  }
  .info {
    margin-left: 10px;
  }
  /deep/ textarea {
    height: 150px;
  }
  .extraData {
    width: 600px;
    height: 500px;
    #jsoneditor {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style scoped>
@import "~jsoneditor/dist/jsoneditor.min.css";
.IotAgentServiceConfig-wrapper {
  background-color: white;
  overflow: hidden;
}

.search-wrapper {
  margin: 20px;
  display: flex;
}

.pull-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

#jsoneditor {
  margin: 20px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
}

#jsoneditor >>> .jsoneditor-menu {
  background-color: #409eff;
  border-bottom: 1px solid #409eff;
}

#jsoneditor >>> .jsoneditor.jsoneditor-mode-text {
  border: thin solid #409eff;
}

#jsoneditor >>> .jsoneditor-poweredBy {
  display: none;
}
</style>
