//新建/更新平台模块信息
const addPlatformAppListUrl = `/gateway/blade-system/mng/platformModelSetting/edit`;
//获取所有埋点类型列表【不分页】
const getBuriedPointUrl = `/gateway/blade-system/mng/platformModelSetting/buriedPointTypeList`;
//根据id获取应用模块的详细信息
const getBuriedPointDetailUrl = `/gateway/blade-system/mng/platformModelSetting/detailById`;
//分类接口
const kindsListUrl = `/gateway/blade-system/mng/platformModelSetting/modelCategoryList`;

//应用详情
const appDetailUrl = `/gateway/hc-portal/developer/info`;
//应用模块配置表(开发者)接口
//应用列表
const deveApplyUrl = `/gateway/hc-portal/developer/page`;
//应用新增或者修改
const deveAddApplyUrl = `/gateway/hc-portal/developer/manage/submit`;
//应用上架/下架
const devePubApplyUrl = `/gateway/hc-portal/developer/manage/shelf`;
//应用删除
const deveDeleApplyUrl = `/gateway/hc-portal/developer/manage/remove`;
//应用模块配置表(园区)接口
//应用列表
const comApplyUrl = `/gateway/hc-portal/community/manage/page`;
//应用删除
const comDeleApplyUrl = `/gateway/hc-portal/community/manage/remove`;
//社区列表
const tenantListUrl = `/gateway/hc-portal/client/tenantListAll`;
//调试二维码
const testQrCodeUrl = `/gateway/hc-portal/developer/testUrls`;
//修改园区信息
const updateCommunity = `/gateway/hc-portal/community/app/update`;
//园区应用详情
const communityInfo = `/gateway/hc-portal/community/app/info`;
export {
  appDetailUrl,
  addPlatformAppListUrl,
  getBuriedPointUrl,
  getBuriedPointDetailUrl,
  kindsListUrl,
  deveAddApplyUrl,
  deveApplyUrl,
  devePubApplyUrl,
  deveDeleApplyUrl,
  comApplyUrl,
  comDeleApplyUrl,
  tenantListUrl,
  testQrCodeUrl,
  updateCommunity,
  communityInfo
};
